<template>
  <router-view />
</template>

<script>
// import { matchMedia } from '@mixins/matchMedia';

// export default {
//   mounted() {
//     const lightTheme = matchMedia("(prefers-color-scheme: light)");
//     lightTheme.addEventListener("change", this.handleThemeChange);
//     this.handleThemeChange(lightTheme);
//   },
//   beforeUnmount() {
//     const lightTheme = matchMedia("(prefers-color-scheme: light)");
//     lightTheme.removeEventListener("change", this.handleThemeChange);
//   },
//   methods: {
//     handleThemeChange(mediaQuery) {
//       const link = document.querySelector("link[rel='icon']");
//       if (mediaQuery.matches) {
//         //link.href = "favicon-light.svg";
//         link.href = "bahk-favicon-white.png";
//       } else {
//         link.href = "bahk-favicon.png";
//       }
//     }
//   }
// }
</script>

<style>
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('@assets/fonts/Roboto-300.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@assets/fonts/Roboto-400.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('@assets/fonts/Roboto-500.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('@assets/fonts/Roboto-700.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: 'Roboto';
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
html {
  font-size: 16px;
  min-width: 320px;
  background-color: #000000;
}
body {
  min-height: 100%;
}
html,
body {
  height: 100%;
}
body[class^="modal_"], body[class^="loader_"], body[class="sidebar_overlay"] {
  overflow: hidden;
}
.user-select-none {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.icon-editar-icono, .icon-reactivar-icon, .icon-mas-icon, button:not(i) {
  color: #000000;
}
</style>
