import { defineStore } from 'pinia';
import { useMenuBahk } from '@store/menu.js';

export const useUsuarioBahk = defineStore({
  id: 'usuarioBahk',
  state: () => ({
    session: null,
  }),
  getters: {
    getSession: (state) => state.session,
    verificarPermiso: (state) => {
      if (state.session && state.session.modulos) {
        return (ruta) => (state.session.modulos.find((permiso) => permiso.moduloRol.route === ruta) ? true : false);
      }
      return () => false;
    },
    getNombreUsuario(state) {
      if (state.session) {
        return `${state.session.nombre ? state.session.nombre + ' ' : ''}${state.session.apellidop ? state.session.apellidop + ' ' : ''}${state.session.apellidom ? state.session.apellidom + ' ' : ''}`;
      }
      return '';
    },
    getDescripcionRol(state) {
      if (state.session) {
        return state.session.rol;
      }
      return '';
    },
    getRolId(state) {
      if (state.session) {
        return state.session.rolId;
      }
      return null;
    },
  },
  actions: {
    setSession(sesion) {
      delete sesion.token;
      delete sesion.refreshToken;
      sessionStorage.setItem('session', JSON.stringify(sesion));
      this.$patch((state) => {
        state.session = sesion;
      });
    },
    cerrarSesion() {
      this.$reset();
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.removeItem('session');
      const menu = useMenuBahk();
      menu.setCollapsedMenu(false);
    },
  },
});
