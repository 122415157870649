import { defineStore } from 'pinia';

export const useMenuBahk = defineStore({
  id: 'menuBahk',
  state: () => ({
    collapsedMenu: false,
  }),
  actions: {
    setCollapsedMenu(collapse) {
      this.collapsedMenu = collapse;
      if (collapse) {
        document.body.classList.add('sidebar_overlay');
      } else {
        document.body.classList.remove('sidebar_overlay');
      }
    },
  },
});
