import { createRouter, createWebHistory } from 'vue-router';
import { useUsuarioBahk } from '@store/user.js';
import { PAGES, ROLES } from '@constants/routes';

const routes = [
  {
    path: '/',
    name: PAGES.LOGIN,
    component: () => import('../views/LogIn'),
    meta: {
      title: 'Iniciar sesión',
      isPublic: true,
    },
  },
  {
    path: '/puntoventa',
    name: PAGES.PUNTO_VENTA,
    component: () => import('../views/PuntoVenta'),
    meta: {
      title: 'Punto de venta',
      isPublic: false,
    },
  },
  {
    path: '/cortes',
    name: PAGES.CORTES,
    component: () => import('../views/Cortes'),
    meta: {
      title: 'Cortes',
      isPublic: false,
    },
  },
  {
    path: '/dashboard',
    name: PAGES.DASHBOARD,
    component: () => import('../views/Dashboard'),
    meta: {
      title: 'Dashboard',
      isPublic: false,
    },
  },
  {
    path: '/citas',
    name: PAGES.CITAS,
    component: () => import('../views/Citas'),
    meta: {
      title: 'Citas',
      isPublic: false,
    },
  },
  {
    path: '/precios',
    name: PAGES.PRECIOS,
    component: () => import('../views/Precios'),
    meta: {
      title: 'Precios',
      isPublic: false,
    },
  },
  {
    path: '/egresos',
    name: PAGES.EGRESOS,
    component: () => import('../views/Egresos'),
    meta: {
      title: 'Egresos',
      isPublic: false,
    },
  },
  {
    path: '/compras',
    name: PAGES.COMPRAS,
    component: () => import('../views/Compras'),
    meta: {
      title: 'Compras',
      isPublic: false,
    },
  },
  {
    path: '/pacientes',
    name: PAGES.PACIENTES,
    component: () => import('../views/Historial'),
    meta: {
      title: 'Historial',
      isPublic: false,
    },
  },
  {
    path: '/inventario',
    name: PAGES.INVENTARIO,
    component: () => import('../views/Inventario'),
    meta: {
      title: 'Inventario',
      isPublic: false,
    },
  },
  {
    path: '/empleados',
    name: PAGES.EMPLEADOS,
    component: () => import('../views/Empleados'),
    meta: {
      title: 'Empleados',
      isPublic: false,
    },
  },
  {
    path: '/usuarios',
    name: PAGES.USUARIOS,
    component: () => import('../views/Usuarios'),
    meta: {
      title: 'Usuarios',
      isPublic: false,
    },
  },
  {
    path: '/proveedores',
    name: PAGES.PROVEEDORES,
    component: () => import('../views/Proveedores'),
    meta: {
      title: 'Proveedores',
      isPublic: false,
    },
  },
  {
    path: '/restringido',
    name: PAGES.RESTRINGIDO,
    component: () => import('../views/AccesoRestringido'),
    meta: {
      title: 'Acceso Restringido',
      isPublic: false,
    },
  },
  {
    path: '/vistaprueba',
    name: 'vistaprueba',
    component: () => import('../views/VistaPrueba'),
    meta: {
      title: 'Vista prueba',
      isPublic: true,
    },
  },
  {
    path: '/contrasena',
    name: PAGES.CONTRASENA,
    component: () => import('../views/Contrasena'),
    meta: {
      title: 'Cambio de contraseña',
      isPublic: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `BAHK ${to.meta.title ? '- ' + to.meta.title : ''}`;

  const isPublic = to.meta.isPublic || false;
  const usuarioBahk = useUsuarioBahk();
  let isAuthenticated = usuarioBahk.session || false;

  if (!isAuthenticated) {
    const sesionToken = sessionStorage.getItem('token');
    const sesionRefreshToken = sessionStorage.getItem('refreshToken');
    const sesionUsuario = sessionStorage.getItem('session');
    if (sesionToken && sesionRefreshToken && sesionUsuario) {
      usuarioBahk.setSession(JSON.parse(sesionUsuario));
      isAuthenticated = true;
    }
  }
  if (!isPublic && !isAuthenticated) {
    next({ name: PAGES.LOGIN });
  } else if (to.name === PAGES.LOGIN && !isAuthenticated) {
    next();
  } else if (to.name === PAGES.LOGIN && isAuthenticated) {
    if (usuarioBahk.type === ROLES.CAJERO || usuarioBahk.type === ROLES.CONTABILIDAD) {
      next({ name: PAGES.PUNTO_VENTA });
    } else {
      next({ name: PAGES.DASHBOARD });
    }
  } else if (to.name === PAGES.RESTRINGIDO) {
    next();
  } else {
    if (usuarioBahk?.session?.modulos && usuarioBahk.session.modulos.find((x) => x.moduloRol.route === to.name)) {
      next();
    } else {
      next({ name: PAGES.PUNTO_VENTA });
    }
  }
});

export default router;
