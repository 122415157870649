export const PAGES = Object.freeze({
  LOGIN: 'Login',
  PUNTO_VENTA: 'PuntoVenta',
  CORTES: 'Cortes',
  DASHBOARD: 'Dashboard',
  CITAS: 'Citas',
  PRECIOS: 'Precios',
  EGRESOS: 'Egresos',
  COMPRAS: 'Compras',
  PACIENTES: 'Pacientes',
  INVENTARIO: 'Inventario',
  EMPLEADOS: 'Empleados',
  USUARIOS: 'Usuarios',
  PROVEEDORES: 'Proveedores',
  RESTRINGIDO: 'restringido',
  CONTRASENA: 'Contrasena'
})

export const ROLES = Object.freeze({
  DIRECTOR_GENERAL: 1,
  DIRECTOR_ADMINISTRATIVO: 2,
  GERENTE: 3,
  CONTABILIDAD: 4,
  CAJERO: 5,
  MEDICO: 6,
  TERAPEUTICO: 7,
})