import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@assets/iconfonts/fontello.css'
import '@assets/fonts/Roboto-300.woff2'
import '@assets/fonts/Roboto-400.woff2'
import '@assets/fonts/Roboto-500.woff2'
import '@assets/fonts/Roboto-700.woff2'
import { createPinia } from 'pinia'
import  VueHtmlToPaper from './/VueHtmlToPaper'

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(VueHtmlToPaper)
app.mount('#app')
